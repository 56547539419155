// Dependencies
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

// Styles
import colors from "styles/colors";
import dimensions from "styles/dimensions";

// Components
import Layout from "components/Layout";
import ProjectCard from "components/ProjectCardv2";
import ExperienceCard from "components/ExperienceCard";

// Local images
import wave from "images/wave.png";
import cartoon from "images/illustration.png";
import bq from "images/bq.png";

// Text + Image
const Hero = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5em;

    margin-top: 1em;
    margin-bottom: 5em;

    @media (min-width: ${dimensions.minwidthXLscreen}px) {
        gap: 8em;
    }

    @media (max-width: 960px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

// Text + Arrow
const HeroText = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        gap: 0.75em;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        gap: 1em;
    }
`

// HeroText: Line 1
const HeroIntro = styled("div")`
    h1 {
        margin: 0;
        font-weight: 800;
    }

    span {
        white-space: nowrap;
    }

    // "I'm Bob"
    h1 > :nth-of-type(2) {
        padding-right: 1em;
    }
`

// HeroText: Wave emoji
const HeroWave = styled("div")`
    width: 1em;
    height: 1em;
    position: relative;
    top: 0.1em;
    margin-left: 0.2em;
    margin-right: 0.25em;
    display: inline-block;
    line-height: 0;

    img {
        width: 100%;
        -webkit-user-drag: none; // No dragging
    }
`

// HeroText: Line 2
const HeroDesc = styled("div")`
    h2 {
        font-weight: 700;
        margin: 0;
        line-height: 1.2;
    }

    span {
        white-space: nowrap;
    }

    a, span a {
        text-decoration: none;

        transition: all 250ms ease-in-out; 

        &.duke { 
            color: ${colors.dukeblue500};
        }
        
        &.amazon { 
            color: ${colors.amazonorange500};
        }

        &:hover {
            cursor: pointer;
            transition: all 125ms ease-in-out;

            &.duke { 
                color: ${colors.dukeblue700};
                background-color: ${colors.dukeblue200};
                //box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.08);
            }

            &.amazon { 
                color: ${colors.amazonorange700};  
                background-color: ${colors.amazonorange200};
                //box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.08);
            }
        }
    }
`

// HeroText: Arrow
const Arrow = styled("div")`
    margin: 0.5em 0 0.5em 0;

    span {
        color: ${colors.bqblue500};
        font-size: 1.1em;
    }

    animation: bounce 1.25s ease-in-out 1.25s infinite;

    @keyframes bounce {
        50% {
            transform: translateY(4px);
        }
    }
`

// Illustration
const HeroImage = styled("div")`
    width: 6em;

    img {
        width: 100%;
    }
`

// Main page content container
const Content = styled("div")`
    display: flex;
    flex-direction: column;
`

// Experiences container
const Experiences = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2em;
    gap: 2em;

    @media (max-width: ${dimensions.maxwidthDesktop}px) {
        gap: 1em;
    }

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        flex-direction: column;
        gap: 2em;
    }
`

// Projects container
const Projects = styled("div")`
    display: flex;
    flex-direction: column;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
`

const RenderBody = ({ projects, experiences, meta }) => {
    return (
        <>
            <Helmet
                title={meta.title}
                titleTemplate={`${meta.title}`}
                meta={[
                    {
                        name: `description`,
                        content: meta.description,
                    },
                    {
                        property: `og:title`,
                        content: meta.title,
                    },
                    {
                        property: `og:description`,
                        content: meta.description,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: meta.author,
                    },
                    {
                        name: `twitter:title`,
                        content: meta.title,
                    },
                    {
                        name: `twitter:description`,
                        content: meta.description,
                    },
                    {
                        name: 'twitter:image',
                        content: {bq}
                    }
                ].concat(meta)}
            />

            <Hero>
                <HeroText>
                    <HeroIntro
                        as={motion.div}
                        initial={{
                            y: 10,
                            opacity: 0
                        }}
                        animate={{
                            y: 0,
                            opacity: 1
                        }}
                        transition={{
                            type: "tween",
                            delay: 0.1,
                            ease: "easeInOut"
                        }}
                    >
                        <h1>
                            <span>Hello 
                            <HeroWave>
                                <img src={wave} alt="Hand-wave emoji" />
                            </HeroWave></span>
                            <span>I'm Bob!</span>
                        </h1>
                        
                    </HeroIntro>
                    
                    <HeroDesc
                        as={motion.div}
                        initial={{
                            y: 10,
                            opacity: 0
                        }}
                        animate={{
                            y: 0,
                            opacity: 1
                        }}
                        transition={{
                            type: "tween",
                            delay: 0.1,
                            ease: "easeInOut"
                        }}
                    >
                        <h2> 
                            4th year student,

                            cs <a 
                            href="https://trinity.duke.edu/news/pushing-boundaries-and-creating-history-50-years-computer-science-duke" 
                            target="_blank"
                            rel="noreferrer"
                            className="duke">@duke</a> 
                            
                            
                            {/*sde intern <span><a 
                            href="https://www.amazon.com/" 
                            target="_blank"
                            rel="noreferrer"
                            className="amazon">@amazon</a>.
                            </span>*/}
                        </h2>
                    </HeroDesc>

                    <Arrow
                        as={motion.div}
                        initial={{
                            y: 0,
                            opacity: 0
                        }}
                        animate={{
                            y: 10,
                            opacity: 1
                        }}
                        transition={{
                            type: "tween",
                            delay: 0.7,
                            ease: "easeInOut"
                        }}
                    >
                        <span>&#8595;</span>
                    </Arrow>
                </HeroText>

                <HeroImage
                    as={motion.div}
                    initial={{
                        y: 10,
                        opacity: 0
                    }}
                    animate={{
                        y: 0,
                        opacity: 1
                    }}
                    transition={{
                        type: "tween",
                        delay: 0.1,
                        ease: "easeInOut"
                    }}
                >
                    <img src={cartoon} alt="Cartoon illustration" />
                </HeroImage>
            </Hero>
            
            <Content>
                <Experiences>
                    {experiences.map((experience, i) => (
                        <ExperienceCard 
                            key={i}
                            category={experience.node.technologies_used}
                            title={experience.node.experience_title}
                            description={experience.node.experience_preview_description}
                            uid={experience.node._meta.uid}
                        />
                    ))}
                </Experiences>

                <Projects>
                    <motion.ul>
                        {projects.map((project, i) => (
                            <motion.li 
                                key={i}
                            >
                                <ProjectCard
                                    key={i}
                                    category={project.node.project_category}
                                    title={project.node.project_title}
                                    description={project.node.project_preview_description}
                                    thumbnail={project.node.project_preview_thumbnail}
                                    uid={project.node._meta.uid}
                                />
                            </motion.li>
                        ))}
                    </motion.ul>
                </Projects>
            </Content>
        </>
    );
};

export default ({ data }) => {
    const projects = data.prismic.allProjects.edges;
    const experiences = data.prismic.allExperiences.edges;
    const meta = data.site.siteMetadata;

    return (
        <Layout>
            <RenderBody projects={projects} experiences={experiences} meta={meta}/>
        </Layout>
    );
}

// Ensure projects is array, meta is object
RenderBody.propTypes = {
    projects: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
}

// GraphQL query
export const query = graphql`
    {
        prismic {
            allProjects(sortBy: project_post_date_DESC) {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_preview_thumbnail
                        project_category
                        project_post_date
                        _meta {
                            uid
                        }
                    }
                }
            }
            allExperiences(sortBy: experience_post_date_DESC) {
                edges {
                    node {
                        experience_title
                        experience_preview_description
                        experience_preview_thumbnail
                        technologies_used
                        experience_post_date
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`